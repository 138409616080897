<template>
  <div class="container-base section-container-padding bg-white">
    <my-project-header
      :title="project.name"
      :isNew="project.isDayAgo"
      :lastUpdate="project.started"
      :pipelinesUsed="pipelinesUsed"
      :documentUrl="project.botName + '/document/' + project.docId" />

    <div class="myproj-container">
      <b-tabs v-model="step" content-class="mt-3">
        <b-tab>
          <template v-slot:title>
            <img :src="'/assets/images/icon_myproject_view.svg'"/><span>{{ $t('file.view') }}</span>
          </template>
          <b-row class="file-name-back">
            <back-button class="back-button my-auto" />
            <div class="library-document-title openSans-100 fz-28 file-title-trim" v-b-tooltip.hover :title="project.docTitle">{{ project.docTitle }}</div>
          </b-row>
          <b-row>
            <b-col cols="12" lg="8" >
              <div v-if="project.fileName === null">
                <br>
                <h2 class="library-document-title openSans-100 fz-28">No preview available</h2>
              </div>
              <div v-else>
                <preloader :type="'pdf-preview'" v-if="preloader" />
                <preview-file v-if="project.fileName != '' && project.projectId != ''"
                  :project-id="project.projectId"
                  :doc-id="project.docId"
                  :filename="project.fileName"
                  @preloader-display="displayPreloader"/>
              </div>
            </b-col>
            <b-col cols="4">
              <b-card class="library-card-tag-color">
                <div v-if="project.ingested" class="text-left">
                  <h6 class="myproj-glosary-title openSans-600 fz-17">{{ $t('file.glossary.titleProcessed') }}</h6>
                  <p class="myproj-glosary-title openSans-400 fz-17 myproj-glosary-subtitle">{{ $t('file.glossary.subtitleProcessed') }}</p>
                  <b-button class="button-primary openSans-600 fz-12 ta-center myproj-glosary-button" @click="enrichDocument">
                    {{ $t('proyect.buttons.enrichDocument') }}
                  </b-button>
                  <h3 class="myproj-glosary-separate-line section-title-right-line openSans-100 fz-23"/>
                  <h6 class="myproj-glosary-group-title openSans-600 fz-15">{{ $t('file.glossary.liAvaiable') }}</h6>
                  <b-row class="myproj-glosary-paragraph">
                    <img :src="'/assets/images/icon_myproject_glosary_ontology.svg'"/>
                    <b-card-title class="myproj-glosary-paragraph-title openSans-600 fz-16">{{ $t('file.glossary.ontologyCreation') }}</b-card-title>
                  </b-row>
                  <b-card-text class="myproj-glosary-paragraph-description openSans-400 fz-13">{{ glossary.ontologyCreation }}</b-card-text>
                  <br>
                  <b-row class="myproj-glosary-paragraph">
                    <img :src="'/assets/images/icon_myproject_glosary_query.svg'"/>
                    <b-card-title class="myproj-glosary-paragraph-title openSans-600 fz-16">{{ $t('file.glossary.queryExpansion') }}</b-card-title>
                  </b-row>
                  <b-card-text class="myproj-glosary-paragraph-description openSans-400 fz-13">{{ glossary.queryExpansion }}</b-card-text>
                </div>
                <div v-else class="text-left">
                  <b-row class="message-row">
                    <img class="va-middle processing-icon-midle mx-2" :src="'/assets/images/icon_processing_small.svg'"/>
                    <span class="openSans-600 fz-17 processing-title">{{ $t('file.glossary.titleProcessing') }}</span>
                  </b-row>
                  <span class="openSans-400 fz-17">
                    {{ $t('file.glossary.subtitleProcessing') }}
                  </span>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import MyProjectHeader from '@/modules/common/components/MyProjectHeader'
import PreviewFile from "@/modules/common/components/PreviewFile";
import Preloader from "@/modules/common/components/Preloader";
import ProjectService from "@/modules/projects/services/ProjectService";
import BackButton from "@/modules/common/components/BackButton.vue"
import LibraryService from "@/modules/library/services/LibraryService.js"
import store from '@/store'

var currentProject = {}

export default {
  name: "File",
  components: {
    'preview-file': PreviewFile,
    'my-project-header': MyProjectHeader,
    'preloader': Preloader,
    'back-button': BackButton },
  data () {
    return {
      preloader: true,
      pending: false,
      glossary: {
        ontologyCreation: this.$t('proyect.glossary.ontologyCreation'),
        queryExpansion: this.$t('proyect.glossary.queryExpansion'),
      },
      project: {
        fileName: '',
        title: '',
        projectId: null,
        docId: null,
        botName: '',
        name: '',
        docMime: '',
        isDayAgo: false,
        started: '',
        ingested: false
      },
      step: 0,
      polling: null,
      currentPage: 1,
      pipelinesUsed: []
    }
  },
  methods: {
    displayPreloader(boolean){
      this.preloader = boolean
    },
    getFileName() {
      LibraryService.getDocument(this.$router.currentRoute.params.bot,
                                 this.$router.currentRoute.params.fileId, 1).then((response) => {
                                   
        this.project.docTitle = response.data.title
        this.project.fileName = response.data.stored_name
      })
    },
    enrichDocument() {
      this.$router.push({
        path: `/${this.$route.params.bot}/projects/${this.$route.params.projectId}`,
        query: {step: 2}
      })
    },
    initializeProject() {
      this.project.name = currentProject.project_name
      this.project.botName = store.getters.getInstance
      this.project.ingested = currentProject.ingestion_completed
      this.project.projectId = currentProject.id
      this.project.docId = this.$router.currentRoute.params.fileId
      this.project.started = currentProject.created

      let created = new Date(this.project.started)
      let today = new Date(Date.now())
      this.project.isDayAgo = (today - created) <= 86400000

      let ingestionConfig = currentProject.pipelineConfigs['INGESTION']
      this.pending = ingestionConfig && ingestionConfig.status == "PENDING"

      if (!this.project.ingested) {
        this.polling = setInterval(() => {
          ProjectService.get(this.$router.currentRoute.params.projectId,
                             this.$router.currentRoute.params.bot).then(response => {
            this.project.ingested = response.data.ingestion_completed
            this.pending = !this.project.ingested
            if (this.project.ingested) {
              clearInterval(this.polling)
            }
          }, error => {
            console.log(error)
          })
        }, 5000)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  beforeRouteEnter(to, from, next) {
    ProjectService.get(to.params.projectId, to.params.bot).then(response => {
      let configs = {}
      let project = response.data
      project.pipeline_configs.forEach(config => {
        configs[config.type] = config
      })
      project['pipelineConfigs'] = configs
      currentProject = project
      next()
    }, error => {
      console.log(error)
      next(false)
    })
  },
  created() {
    this.preloader = true
    this.initializeProject()
    this.getFileName()
    this.currentPage = this.$route.query.page
  }
}
</script>

<style lang="scss" scoped>

.processing-title {
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.message-row {
  margin-bottom: 20px;
}

.processing-icon-midle {
  width: 38px;
  height: 37px;
}

.file-name-back {
  margin-bottom: 20px;
  padding-left: 20px;
  margin-top: 15px;
}


.back-button {
  margin-right: 20px;
}

div.col-8{
  border: 4px solid white;
  box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.25);
}
</style>

